export const convertHexToRgba = (color: string, opacity: number) => {
  const removeHash = color.replace("#", "");
  let hex = "";
  if (removeHash.length === 3) {
    hex = removeHash + removeHash;
  } else if (removeHash.length === 4) {
    hex =
      removeHash.slice(0, 3) +
      removeHash.slice(0, 3) +
      removeHash.slice(3, 4) +
      removeHash.slice(3, 4);
  } else {
    hex = removeHash;
  }
  const red = parseInt(hex.substring(0, 2), 16);
  const green = parseInt(hex.substring(2, 4), 16);
  const blue = parseInt(hex.substring(4, 6), 16);
  return `rgba(${red}, ${green}, ${blue}, ${opacity / 100})`;
};
